<template>
  <div>
    <input type="file" ref="file" v-on:change="handleFileUpload"/>
    <div v-on:click="addFile" :class="class_">
      <button class="btn btn-outline-primary text-nowrap w-100"><i class="fe fe-upload-cloud"></i> Chọn file</button>
      <div class="progress mt-4" v-if="uploadPercent > 0">
        <div class="progress-bar" role="progressbar" :style="`width: ${uploadPercent}%;`" :aria-valuenow="uploadPercent" aria-valuemin="0" aria-valuemax="100">{{ uploadPercent }}%</div>
      </div>
      <div v-if="uploadFinished" class="mt-4">
        <Svg/>
        <div class="text-dark-success" v-if="uploadResult === 'success'">
          <svg class="bi flex-shrink-0" width="30" height="30"><use xlink:href="#check-circle-fill"></use></svg>
        </div>
        <div class="text-dark-danger" v-if="uploadResult === 'danger'">
          <svg :title="uploadError" class="bi flex-shrink-0" width="30" height="30"><use xlink:href="#exclamation-triangle-fill"></use></svg>
        </div>
        <p v-if="message">{{ message }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Svg from "@/components/Svg";

export default {
  name: "Upload",
  props: {
    class: {
      type: String,
      default: "justify-content-center d-table-cell align-items-center cursor-pointer dropzone border-dashed border-primary text-center"
    },
    message: String
  },
  components: {
    Svg
  },
  data() {
    return {
      showProgress: false,
      uploadPercent: 0,
      uploadFinished: false,
      uploadResult: "",
      uploadError: "",
      class_: ""
    }
  },
  methods: {
    addFile() {
      this.$refs.file.value = null;
      this.uploadPercent = 0;
      this.uploadFinished = false;
      this.uploadResult = "";
      this.uploadError = "";
      this.$refs.file.click();
    },
    onUploadProgress(progressEvent) {
      this.uploadPercent = Math.round((progressEvent.loaded / progressEvent.total ) * 100) - 1;
      this.$emit("onUploadProgress", this.uploadPercent);
    },
    handleFileUpload() {
      let file = this.$refs.file.files[0];

      ApiService.upload(file, {onUploadProgress: this.onUploadProgress})
          .then(({data}) => {
            if (data.data && data.data.upload) {
              this.$emit("uploadFinished", data.data.upload);
              this.uploadPercent = 0;
              this.uploadFinished = true;
              this.uploadResult = "success";
            } else {
              let message = "";
              if (data.errors) {
                message = data.errors[0].message;
              }
              this.$emit("uploadFinished", message);
              this.uploadPercent = 0;
              this.uploadFinished = true;
              this.uploadResult = "danger";
              this.uploadError = message;
            }
          })
          .catch((response) => {
            this.uploadError = response.message;
            this.$emit("uploadFinished", response.message);
            this.uploadPercent = 0;
            this.uploadFinished = true;
            this.uploadResult = "danger";
          });
    },
  },
  created() {
    this.class_ = this.class;
  }
}
</script>
